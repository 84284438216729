//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

import api from "@/api/api";
import NavLinks from "@/components/main/NavLinks";
import RestrictedMessage from "@/components/errorBlocks/RestrictedMessage";
import Loader from "@/components/main/Loader";
import ExternalNeed from './components/ExternalNeed';
import ExternalDocuments from './ExternalDocuments';

export default {
  name: "ExternalTraining",
  components: { NavLinks, RestrictedMessage, Loader, ExternalNeed, ExternalDocuments },
  data() {
    return {
      isLoader: false,
      restrictedError: '',
      pressed: 'needs',
      needs: null,
      sort: 'up',
    }
  },
  computed: {
    isPressedNeeds() {
      return this.pressed === 'needs'; 
    },
    isPressedDocuments() {
      return this.pressed === 'documents'; 
    },
  },
  methods: {
    toggleSort() {
      if (this.sort === 'up') this.sort = 'down';
      else this.sort = 'up';
      this.sortedNeeds();
    },
    sortedNeeds() {
      if (this.sort === 'up') {
        this.needs.sort((first, second) => {
          return moment(first.DATE_ASSIGN, 'DD.MM.YYYY hh:mm:ss').isAfter(moment(second.DATE_ASSIGN, 'DD.MM.YYYY hh:mm:ss'))
        })
      } else {
        this.needs.sort((first, second) => {
          return moment(first.DATE_ASSIGN, 'DD.MM.YYYY hh:mm:ss').isBefore(moment(second.DATE_ASSIGN, 'DD.MM.YYYY hh:mm:ss'))
        })
      }
    },
  },
  async created() {
    try {
      const studentExternalCoursesResponse = await api.getStudentExternalCourses(this.$store.state.worker.id);
      if (studentExternalCoursesResponse.data.error) throw new Error(studentExternalCoursesResponse.data.errorStr);
      this.needs = studentExternalCoursesResponse.data.result.needs;
    } catch (error) {
      console.error(error);
    }
  }
}
